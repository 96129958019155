import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Finance from './Pages/Finance';
import Layout from './components/Layout';

import Contact from './Pages/Contact';

import Newpost from './Pages/Newpost';
import About from './Pages/About';
import Detail from './Pages/Detail';
import Business from './Pages/Business';
import Health from './Pages/Health';
import Tech from './Pages/Tech';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          {/* Define routes without Layout */}
          <Route path="/" element={<App />} />
          <Route path="/login" element={<Login />} />
          {/* Routes that should use Layout */}
          <Route
            path="/home/*"
            element={
              <Layout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path=":id" element={<Home />} />
                  <Route path="new/:id" element={<Newpost />} />
                  <Route path="business" element={<Business />} />
                  <Route path="finance" element={<Finance />} />
                  <Route path="technology" element={<Tech />} />
                  <Route path="health" element={<Health />} />
                  <Route path="business/:id" element={<Detail />} />
                  <Route path="finance/:id" element={<Detail />} />
                  <Route path="technology/:id" element={<Detail />} />
                  <Route path="health/:id" element={<Detail />} />
                  <Route path="about" element={<About />} />
                  <Route path="contact" element={<Contact />} />
                </Routes>
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ChakraProvider>
);

reportWebVitals();
