import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
  Select,
} from '@chakra-ui/react';

const Newpost = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [postContent, setPostContent] = useState({
    category: 'health',
    date: 'Today',
    preparation: '',
    title: '',
    subtitle: '',
    content: '',
    comments: [],
  });
  const [authenticated, setAuthenticated] = useState(false);
  const toast = useToast();

  const handleLogin = () => {
    // Simulating a simple authentication process for demonstration purposes
    if (username === 'a' && password === 'b') {
      // Proceed with allowing post submission
      setAuthenticated(true);
      toast({
        title: 'Authentication Successful',
        description: 'You are now authenticated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      // Show error toast for incorrect credentials
      toast({
        title: 'Authentication Failed',
        description: 'Invalid username or password',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePostSubmit = async () => {
    try {
      const preparationValue = parseInt(postContent.preparation, 10);

      // Check if the parsed value is a valid integer
      if (!isNaN(preparationValue)) {
        // Update the postContent with the integer preparation value
        const updatedPostContent = { ...postContent, preparation: preparationValue };
  
        // Submit new post to the database with the updated post content
        const response = await fetch(`https://api.goodmanager.in/home/${updatedPostContent.category}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedPostContent),
        });
      console.log(JSON.stringify(updatedPostContent));
      if (!response.ok) {
        console.log(response);
        throw new Error('Network response was not ok');
      }

      }
      // Submit new post to the database with the entered post content
    else{
    console.error('Invalid preparation value: Not a valid number');
    // Handle the case where preparation value is not a valid number
  }
      
      // Show success toast
      toast({
        title: 'Post Added',
        description: 'Your post was added successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setPostContent({
        category: 'health',
        preparation: '',
        date:'today',
        title: '',
        subtitle: '',
        content: '',
       
      });
    } catch (error) {
      console.error('Error adding post:', error);
    }
  };

  return (
    <Box maxW="500px" mx="auto" mt="40px">
      <Heading mb="4">Authentication</Heading>
      <VStack spacing="4">
        <FormControl id="username" isRequired>
          <FormLabel>Username</FormLabel>
          <Input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleLogin}>
          Authenticate
        </Button>
      </VStack>
      {authenticated && (
        <Box mt="40px">
          <Heading mb="4">New Post</Heading>
          <VStack spacing="4">
            <FormControl id="category" isRequired>
              <FormLabel>Category</FormLabel>
              <Select
                value={postContent.category}
                onChange={(e) =>
                  setPostContent({ ...postContent, category: e.target.value })
                }
              >
                <option value="health">Health</option>
                <option value="business">Business</option>
                <option value="technology">Technology</option>
                <option value="finance">Finance</option>
              </Select>
            </FormControl>
            <FormControl id="preparation" isRequired>
              <FormLabel>Preparation</FormLabel>
              <Input
                type="number"
                placeholder="Enter preparation"
                value={postContent.preparation}
                onChange={(e) =>
                  setPostContent({ ...postContent, preparation: e.target.value })
                }
              />
            </FormControl>
            <FormControl id="title" isRequired>
              <FormLabel>Title</FormLabel>
              <Input
                type="text"
                placeholder="Enter title"
                value={postContent.title}
                onChange={(e) =>
                  setPostContent({ ...postContent, title: e.target.value })
                }
              />
            </FormControl>
            <FormControl id="subtitle" isRequired>
              <FormLabel>Subtitle</FormLabel>
              <Input
                type="text"
                placeholder="Enter subtitle"
                value={postContent.subtitle}
                onChange={(e) =>
                  setPostContent({ ...postContent, subtitle: e.target.value })
                }
              />
            </FormControl>
            <FormControl id="content" isRequired>
              <FormLabel>Content</FormLabel>
              <Textarea
                placeholder="Enter your post content"
                value={postContent.content}
                onChange={(e) =>
                  setPostContent({ ...postContent, content: e.target.value })
                }
              />
            </FormControl>
            <Button colorScheme="blue" onClick={handlePostSubmit}>
              Submit Post
            </Button>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default Newpost;
