import Header from './Header';
import Footer from './Footer';
import favicon from '../illustrations/favicon.ico'
const Layout = ({ children }) => {
  return (
    
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      
      <Header />
      <div style={{ flex: 1 }}>{children}</div>
      <Footer />
    </div>

  );
};

export default Layout;
