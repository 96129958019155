import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Divider,
  Button,
  VStack,
  HStack,
  IconButton,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Textarea,
  useToast,
  Spacer,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { FcPrevious } from "react-icons/fc";
import { FcNext } from "react-icons/fc";
import { Icon } from '@chakra-ui/react';

const    Health = () => {
  const [businessPosts, setBusinessPosts] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [commentText, setCommentText] = useState('');
  const [showCommentsCount, setShowCommentsCount] = useState(3);
  const [showCommentsStart, setShowCommentsStart] = useState(0);
  const [showCommentsEnd, setShowCommentsEnd] = useState(10);
  const toast = useToast();
  const [currentPostIndex, setCurrentPostIndex] = useState(0);

const currentPost = businessPosts[currentPostIndex];

  const fetchBusinessPosts = async () => {
    try {
      const response = await fetch('https://api.goodmanager.in/home/health');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const formattedData = data.map(({ _id, category, date, preparation, title, subtitle, content, comments }) => ({
        id: _id,
        category,
        date,
        preparation,
        title,
        subtitle,
        content,
        comments,
      }));
      setBusinessPosts(formattedData);
    } catch (error) {
      console.error('Error fetching business posts:', error);
    }
  };

  useEffect(() => {
    document.title="Good Manager|Health Management"

    fetchBusinessPosts();
  }, []);

  
  return (
    <Box maxW="800px" mx="auto" py="6">
    <Heading as="h1" fontSize="2xl" mb="4">
        Learnings and Implementations
    </Heading>
    {businessPosts.map((post) => (
      <Link key={post.id} to={`/home/health/${post.id}`} style={{ textDecoration: 'none' }}>
        <Box bg="white" p="4" boxShadow="md" mb="4">
          <VStack align="start">
            <Badge colorScheme="blue">{post.category}</Badge>
            <HStack w="100%">
              <Text fontSize="sm" color="gray.500" flexGrow="1">
                {post.date}
              </Text>
              <Text fontSize="sm" color="gray.500" flexShrink="1" textAlign="right">
                Preparation: {post.preparation} hrs
              </Text>
            </HStack>
            <Heading as="h2" fontSize="xl" my="2">
              {post.title}
            </Heading>
            <Text fontSize="md" color="gray.600">
              {post.subtitle}
            </Text>
          </VStack>
        </Box>
      </Link>
    ))}
  </Box>
  );
};

export default  Health;
