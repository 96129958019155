{/*import { Box, Flex, Input, Button, useBreakpointValue,Image,List,ListItem } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState,useEffect} from 'react';
import { MdEngineering } from 'react-icons/md';
import { TiBusinessCard } from 'react-icons/ti';
import { GiLifeBar } from 'react-icons/gi';
import { TbZoomMoney } from "react-icons/tb";
import { IoPersonCircle } from 'react-icons/io5';
import { useSound } from 'use-sound';
import click from '../sounds/click.wav';
import slogo from '../sounds/slogo.wav'
import { Link } from 'react-router-dom';
import logo  from '../illustrations/logo.png'


export const useButtonClickSound = () => {
  const [playClick] = useSound(click);
  return playClick;
};
export const useLogoClickSound = () => {
    const [logoclick]= useSound(slogo);
    return logoclick;
  };

  
const Header = ({ isLoggedIn }) => {
  
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });
  const playClick = useButtonClickSound();
  const logoclick =useLogoClickSound();
  const buttonMarginBase = { base: '0.5rem', md: '0.75rem' };
  const buttonMarginMobile = { base: '0.5rem', md: '0' };
  const initialActiveButton = localStorage.getItem('activeButton') || '';
  const [activeButton, setActiveButton] = useState(initialActiveButton);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
   

  const handleButtonClick = (buttonName) => {
   
    if(buttonName!=="Logo"){
    setActiveButton((prevButton) => (prevButton === buttonName ? '' : buttonName));
    localStorage.setItem('activeButton', buttonName);
    playClick();
    }else if(buttonName==="Logo"){
        setActiveButton('');
        logoclick()
    }
    setSearchResults([]);
    setSearchQuery('');
  };
  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    try {
      if (query.trim() === '') {
        setSearchResults([]); // Clear results if query is empty
        return;
      }
      if (query.trim() !== '') {
        const response = await fetch(https://api.goodmanager.in/search?query=${encodeURIComponent(query)});
        if (response.ok) {
          const results = await response.json();
          setSearchResults(results); // Assuming results is an array of strings
        } else {
          console.error('Error fetching search results:', response.statusText);
        }
      } else {
        setSearchResults([]); // Clear results if query is empty
      }
    } catch (error) {
      console.error('Error fetching search results:', error.message);
    }
  };
  const handleListItemClick = (result) => {
    setSearchQuery(result.title); // Set search input value to clicked item's title
    setSearchResults([]); // Clear search results
  };
  return (
    
    
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      padding={{ base: '0.5rem', md: '1rem' }}
      bg="gray.200"
      borderRadius="md"
      boxShadow="md"
      flexWrap="wrap"
      
     
    >
        <Link to="/home" onClick={() => handleButtonClick('Logo')}>
       <Box >
       <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
       
        <Image src={logo} alt="My Logo" maxW="800px" maxH="100px" />
        </motion.div>
      </Box>
      </Link>
      <Flex align="center">
      <Box position="relative">
       
        <Input
          variant="filled"
          placeholder="Search..."
          mr={{ base: '0', md: '1rem' }}
          mb={{ base: '1rem', md: '0' }}
          borderRadius="full"
          width={{ base: '100%', md: '400px' }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
       {searchResults.length > 0 && (
            <Box
              position="absolute"
              zIndex="popover"
              top="100%"
              left="0"
              mt="2"
              p="2"
              bg="white"
              borderRadius="md"
              boxShadow="md"
              width={{ base: '100%', md: '400px' }}
            >
              <List spacing={3}>
                {searchResults.map((result, index) => (
                  <ListItem key={index} p="2" borderRadius="md" _hover={{ bg: 'gray.100' }}>
                     <Link to={/home/${result.category}/${result.id}}onClick={() => handleListItemClick(result)}>
                     {result.title}
                     </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
      
        </Box>

        <Link to="/home/technology" onClick={() => handleButtonClick('Technology')}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Button
              variant="rounded"
              mr={buttonMarginBase}
              mb={buttonMarginMobile}
              bg={activeButton === 'Technology' ? 'gray.800' : 'transparent'}
              color={activeButton === 'Technology' ? 'white' : 'black'}
              borderColor={activeButton === 'Technology' ? 'green.800' : 'transparent'}
              size={buttonSize}
              leftIcon={<MdEngineering />}
            >
              Technology
            </Button>
          </motion.div>
        </Link>

        <Link to="/home/business" onClick={() => handleButtonClick('Business')}>
      
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Button
              variant="rounded"
              mr={buttonMarginBase}
              mb={buttonMarginMobile}
              bg={activeButton === 'Business' ? 'gray.800' : 'transparent'}
              color={activeButton === 'Business' ? 'white' : 'black'}
              borderColor={activeButton === 'Business' ? 'gray.800' : 'transparent'}
              size={buttonSize}
              leftIcon={<TiBusinessCard />}
            >
              Business
            </Button>
          </motion.div>
        </Link>

        <Link to="/home/finance" onClick={() => handleButtonClick('Finance')}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Button
              variant="rounded"
              mr={buttonMarginBase}
              mb={buttonMarginMobile}
              bg={activeButton === 'Finance' ? 'gray.800' : 'transparent'}
              color={activeButton === 'Finance' ? 'white' : 'black'}
              borderColor={activeButton === 'Finance' ? 'green.800' : 'transparent'}
              size={buttonSize}
              leftIcon={<TbZoomMoney />}
            >
              Finance
            </Button>
          </motion.div>
        </Link>

        <Link to="/home/health" onClick={() => handleButtonClick('Health')}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Button
              variant="rounded"
              mr={buttonMarginBase}
              mb={buttonMarginMobile}
              bg={activeButton === 'Health' ? 'gray.800' : 'transparent'}
              color={activeButton === 'Health' ? 'white' : 'black'}
              borderColor={activeButton === 'Health' ? 'green.800' : 'transparent'}
              size={buttonSize}
              leftIcon={<GiLifeBar />}
            >
              Health
            </Button>
          </motion.div>
        </Link>
         { /*
        {isLoggedIn ? (
          <Link to="/home/users/profile">
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <Button variant="rounded" leftIcon={<IoPersonCircle />}>
                Profile
              </Button>
            </motion.div>
          </Link>
        ) : (
          <Link to="/home/signin">
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <Button variant="rounded" leftIcon={<IoPersonCircle />}>
                Sign In
              </Button>
            </motion.div>
          </Link>
        )}* close comments here
      </Flex>
    </Flex>
  );
};

export default Header; ###Before responsivenes######
*/
}
import { Box, Flex, Input, Button, useBreakpointValue, Image, List, ListItem, IconButton, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { MdEngineering, MdMenu, MdClose } from 'react-icons/md';
import { TiBusinessCard } from 'react-icons/ti';
import { GiLifeBar } from 'react-icons/gi';
import { TbZoomMoney } from "react-icons/tb";
import { IoPersonCircle } from 'react-icons/io5';
import { useSound } from 'use-sound';
import click from '../sounds/click.wav';
import slogo from '../sounds/slogo.wav';
import { Link } from 'react-router-dom';
import logo from '../illustrations/logo.png';

export const useButtonClickSound = () => {
  const [playClick] = useSound(click);
  return playClick;
};
export const useLogoClickSound = () => {
  const [logoclick] = useSound(slogo);
  return logoclick;
};

const Header = ({ isLoggedIn }) => {
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });
  const playClick = useButtonClickSound();
  const logoclick = useLogoClickSound();
  const buttonMarginBase = { base: '0.5rem', md: '0.75rem' };
  const buttonMarginMobile = { base: '0.5rem', md: '0' };
  const initialActiveButton = localStorage.getItem('activeButton') || '';
  const [activeButton, setActiveButton] = useState(initialActiveButton);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleButtonClick = (buttonName) => {
    if (buttonName !== "Logo") {
      setActiveButton((prevButton) => (prevButton === buttonName ? '' : buttonName));
      localStorage.setItem('activeButton', buttonName);
      playClick();
    } else if (buttonName === "Logo") {
      setActiveButton('');
      logoclick();
    }
    setSearchResults([]);
    setSearchQuery('');
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    try {
      if (query.trim() === '') {
        setSearchResults([]); // Clear results if query is empty
        return;
      }
      if (query.trim() !== '') {
        const response = await fetch(`https://api.goodmanager.in/search?query=${encodeURIComponent(query)}`);
        if (response.ok) {
          const results = await response.json();
          setSearchResults(results); // Assuming results is an array of strings
        } else {
          console.error('Error fetching search results:', response.statusText);
        }
      } else {
        setSearchResults([]); // Clear results if query is empty
      }
    } catch (error) {
      console.error('Error fetching search results:', error.message);
    }
  };

  const handleListItemClick = (result) => {
    setSearchQuery(result.title); // Set search input value to clicked item's title
    setSearchResults([]); // Clear search results
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      padding={{ base: '0.5rem', md: '1rem' }}
      bg="gray.200"
      borderRadius="md"
      boxShadow="md"
      flexWrap="wrap"
    >
      <Box display={{ base: 'block', md: 'none' }} onClick={isOpen ? onClose : onOpen}>
        <IconButton
          icon={isOpen ? <MdClose /> : <MdMenu />}
          variant="outline"
          aria-label="Toggle Navigation"
        />
      </Box>
      <Link to="/home" onClick={() => handleButtonClick('Logo')}>
        <Box>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            {/* Adjust logo size with Chakra UI's Image properties */}
            <Image src={logo} alt="My Logo" maxW="800px" maxH="100px" />
          </motion.div>
        </Box>
      </Link>
      <Flex display={{ base: isOpen ? 'block' : 'none', md: 'flex' }} align="center">
        <Box position="relative" mb={{ base: '1rem', md: '0' }}>
          <Input
            variant="filled"
            placeholder="Search..."
            mr={{ base: '0', md: '1rem' }}
            mb={{ base: '1rem', md: '0' }}
            borderRadius="full"
            width={{ base: '100%', md: '400px' }}
            value={searchQuery}
            onChange={handleSearchChange}
          />
          {searchResults.length > 0 && (
            <Box
              position="absolute"
              zIndex="popover"
              top="100%"
              left="0"
              mt="2"
              p="2"
              bg="white"
              borderRadius="md"
              boxShadow="md"
              width={{ base: '100%', md: '400px' }}
            >
              <List spacing={3}>
                {searchResults.map((result, index) => (
                  <ListItem key={index} p="2" borderRadius="md" _hover={{ bg: 'gray.100' }}>
                    <Link to={`/home/${result.category}/${result.id}`} onClick={() => handleListItemClick(result)}>
                      {result.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
        <Link to="/home/technology" onClick={() => handleButtonClick('Technology')}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Button
              variant="rounded"
              mr={buttonMarginBase}
              mb={buttonMarginMobile}
              bg={activeButton === 'Technology' ? 'gray.800' : 'transparent'}
              color={activeButton === 'Technology' ? 'white' : 'black'}
              borderColor={activeButton === 'Technology' ? 'green.800' : 'transparent'}
              size={buttonSize}
              leftIcon={<MdEngineering />}
            >
              Technology
            </Button>
          </motion.div>
        </Link>
        <Link to="/home/business" onClick={() => handleButtonClick('Business')}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Button
              variant="rounded"
              mr={buttonMarginBase}
              mb={buttonMarginMobile}
              bg={activeButton === 'Business' ? 'gray.800' : 'transparent'}
              color={activeButton === 'Business' ? 'white' : 'black'}
              borderColor={activeButton === 'Business' ? 'gray.800' : 'transparent'}
              size={buttonSize}
              leftIcon={<TiBusinessCard />}
            >
              Business
            </Button>
          </motion.div>
        </Link>
        <Link to="/home/finance" onClick={() => handleButtonClick('Finance')}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Button
              variant="rounded"
              mr={buttonMarginBase}
              mb={buttonMarginMobile}
              bg={activeButton === 'Finance' ? 'gray.800' : 'transparent'}
              color={activeButton === 'Finance' ? 'white' : 'black'}
              borderColor={activeButton === 'Finance' ? 'green.800' : 'transparent'}
              size={buttonSize}
              leftIcon={<TbZoomMoney />}
            >
              Finance
            </Button>
          </motion.div>
        </Link>
        <Link to="/home/health" onClick={() => handleButtonClick('Health')}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Button
              variant="rounded"
              mr={buttonMarginBase}
              mb={buttonMarginMobile}
              bg={activeButton === 'Health' ? 'gray.800' : 'transparent'}
              color={activeButton === 'Health' ? 'white' : 'black'}
              borderColor={activeButton === 'Health' ? 'green.800' : 'transparent'}
              size={buttonSize}
              leftIcon={<GiLifeBar />}
            >
              Health
            </Button>
          </motion.div>
        </Link>
      </Flex>
    </Flex>
  );
};

export default Header;
