import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { Box, Text, Flex, Button } from '@chakra-ui/react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from 'recharts';

const NumberCounter = ({ end, duration }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <CountUp
        start={0}
        end={end}
        duration={duration}
        redraw={true}
        style={{
          fontSize: '2em',
          color: 'rgb(221, 242, 253)',
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 0.5s ease',
        }}
      />
      <Text
        mt="10px"
        fontSize="xl"
        color="rgb(155, 190, 200)"
        opacity={isVisible ? 1 : 0}
        transition="opacity 0.5s ease"
      >
        hours of
      </Text>
    </Box>
  );
};

const HomePage = () => {
  const [preparationValues, setPreparationValues] = useState({
    business: 0,
    health: 0,
    technology: 0,
    finance: 0,
  });
  const [sortedPreparationValues, setSortedPreparationValues] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 21;

  const fetchData = async () => {
    try {
      const categories = ['business', 'health', 'technology', 'finance'];
      const datewisePreparationValues = {};

      const promises = categories.map((category) =>
        fetch(`https://api.goodmanager.in/home/${category}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            const sum = data.reduce(
              (acc, post) => acc + parseInt(post.preparation),
              0
            );
            data.forEach((post) => {
              const postDate = new Date(post.date).toLocaleDateString(
                'en-GB'
              );

              if (!datewisePreparationValues[postDate]) {
                datewisePreparationValues[postDate] = {
                  date: postDate,
                  total: 0,
                };
              }

              datewisePreparationValues[postDate].total += parseInt(
                post.preparation
              );
            });
            return sum;
          })
          .catch((error) => {
            console.error(`Error fetching ${category} posts:`, error);
            return 0;
          })
      );

      const results = await Promise.all(promises);
      const prepValues = categories.reduce((acc, category, index) => {
        acc[category] = results[index];
        return acc;
      }, {});
      setPreparationValues(prepValues);

      const today = new Date();
      const startDate = showHistory
        ? new Date(today.setDate(today.getDate() - pageSize + 1))
        : new Date(today.setDate(today.getDate() - 2));
      today.setDate(
        today.getDate() + (showHistory ? pageSize - 1 : 2)
      );

      for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
        const formattedDate = d.toLocaleDateString('en-GB');
        if (!datewisePreparationValues[formattedDate]) {
          datewisePreparationValues[formattedDate] = {
            date: formattedDate,
            total: 0,
          };
        }
      }

      const sortedDates = Object.keys(datewisePreparationValues).sort(
        (a, b) => {
          const [dayA, monthA, yearA] = a.split('/').map(Number);
          const [dayB, monthB, yearB] = b.split('/').map(Number);
          return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
        }
      );
      

      const sortedPreparationValues = {};
      sortedDates.forEach((date) => {
        sortedPreparationValues[date] =
          datewisePreparationValues[date].total;
      });

      setSortedPreparationValues(sortedPreparationValues);
      
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const renderChartData = (page = 0) => {
    const data = Object.keys(sortedPreparationValues).map((date) => ({
      date,
      count: sortedPreparationValues[date],
    }));

    // Filter out counts greater than 16
    const filteredCounts = Object.values(sortedPreparationValues).filter(
      (count) => count <= 16
    );

    // Calculate the maximum count among the filtered counts
    const maxCount = Math.max(...filteredCounts);

    if (!showHistory) {
      const recentDates = Object.keys(sortedPreparationValues).slice(-3);
      const recentData = recentDates.map((date) => ({
        date,
        count: sortedPreparationValues[date],
      }));
      return recentData.map((item) => ({ ...item, maxCount }));
    } else {
      const paginatedData = data.slice(
        page * pageSize,
        (page + 1) * pageSize
      );
      return paginatedData.map((item) => ({ ...item, maxCount }));
    }
  };
  const getPieChartData = () => {
    // Get today's date
    const today = new Date().toLocaleDateString('en-GB');
  
    // Calculate past 7 days range
    const pastSevenDays = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      pastSevenDays.push(date.toLocaleDateString('en-GB'));
    }
  
    // Calculate average preparation values for past 7 days for each category
    const averageValues = {
      technology: pastSevenDays.reduce((acc, date) => acc + (sortedPreparationValues[date]?.technology || 0), 0) / pastSevenDays.length,
      business: pastSevenDays.reduce((acc, date) => acc + (sortedPreparationValues[date]?.business || 0), 0) / pastSevenDays.length,
      health: pastSevenDays.reduce((acc, date) => acc + (sortedPreparationValues[date]?.health || 0), 0) / pastSevenDays.length,
      finance: pastSevenDays.reduce((acc, date) => acc + (sortedPreparationValues[date]?.finance || 0), 0) / pastSevenDays.length,
    };
  
    // Prepare outer pie chart data
    const outerPieData = [
      {
        name: 'Technology',
        value: averageValues.technology || 0,
      },
      {
        name: 'Business',
        value: averageValues.business || 0,
      },
      {
        name: 'Health',
        value: averageValues.health || 0,
      },
      {
        name: 'Finance',
        value: averageValues.finance || 0,
      },
    ];
  
    // Prepare inner pie chart data for today's values
    const innerPieData = today
      ? [
          {
            name: 'Technology',
            value: preparationValues.technology || 0,
          },
          {
            name: 'Business',
            value: preparationValues.business || 0,
          },
          {
            name: 'Health',
            value: preparationValues.health || 0,
          },
          {
            name: 'Finance',
            value: preparationValues.finance || 0,
          },
        ]
      : [];
  

  
    return { outerPieData, innerPieData };
  };
  
  
  
  useEffect(() => {
    document.title = 'Good Manager';
    fetchData();
  }, [showHistory, currentPage]);

  const handleSeeHistory = () => {
    setShowHistory(!showHistory);
    setCurrentPage(0); // Reset to the first page when toggling history view
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const chartData = renderChartData(currentPage);
  const { outerPieData, innerPieData } = getPieChartData();

  return (
    <Flex
      width="100vw"
      height="calc(100vh - 60px)"
      justifyContent="center"
      alignItems="center"
      bgGradient="linear(to-b, rgb(22, 72, 99), rgb(66, 125, 157))"
      overflowY="auto"
    >
      <Flex
        width="80%"
        height="80%"
        justifyContent="space-around"
        flexWrap="wrap"
        padding="20px"
        boxShadow="0 4px 8px 0 rgba(0,0,0,0.2)"
        borderRadius="10px"
      >
        <Box
          width="45%"
          height="45%"
          textAlign="center"
          fontFamily="cursive"
          color="white"
        >
          <NumberCounter end={preparationValues.technology} duration={3} />
          <Text fontSize="2xl">Technical implementations</Text>
        </Box>
        <Box
          width="45%"
          height="45%"
          textAlign="center"
          fontFamily="cursive"
          color="white"
        >
          <NumberCounter end={preparationValues.business} duration={3} />
          <Text fontSize="2xl">Business strategy learnings</Text>
        </Box>
        <Box
          width="45%"
          height="45%"
          textAlign="center"
          fontFamily="cursive"
          color="white"
        >
          <NumberCounter end={preparationValues.finance} duration={3} />
          <Text fontSize="2xl">Financial analysis</Text>
        </Box>
        <Box
          width="45%"
          height="45%"
          textAlign="center"
          fontFamily="cursive"
          color="white"
        >
          <NumberCounter end={preparationValues.health} duration={3} />
          <Text fontSize="2xl">medical research papers</Text>
        </Box>
        <Box width="100%" mt="80px" textAlign="center" fontFamily="cursive" color="white">
          <Text fontSize="2xl">Momentum</Text>
          <Flex width="100%" justifyContent="space-between" alignItems="center" mt="20px">
            <Box width="100%">
              <Flex width="100%" height="500px" justifyContent="center" alignItems="center" bg="rgba(255, 255, 255, 0.1)" borderRadius="10px">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" interval={0} tick={{ fontSize: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="count" stroke="#1AF8C0" />
                    <Line type="monotone" dataKey="maxCount" stroke="#F81A93" dot={true} />
                  </LineChart>
                </ResponsiveContainer>
              </Flex>
              <Flex justifyContent="center" mt="10px">
                <Button onClick={handleSeeHistory} mr="10px" colorScheme="gray">
                  {showHistory ? 'Hide History' : 'Show History'}
                </Button>
                {showHistory && (
                  <>
                    <Button onClick={prevPage} mr="5px" colorScheme="gray" disabled={currentPage === 0}>Previous</Button>
                    <Button onClick={nextPage} ml="5px" colorScheme="gray" disabled={currentPage * pageSize + pageSize >= Object.keys(sortedPreparationValues).length}>Next</Button>
                  </>
                )}
              </Flex>
            </Box>
          
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default HomePage;
