import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Divider,
  Button,
  VStack,
  HStack,
  IconButton,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Textarea,
  useToast,
  Spacer,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { FcPrevious } from "react-icons/fc";
import { FcNext } from "react-icons/fc";
import { Icon } from '@chakra-ui/react';

const Tech = () => {
  const [businessPosts, setBusinessPosts] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [commentText, setCommentText] = useState('');
  const [showCommentsCount, setShowCommentsCount] = useState(3);
  const [showCommentsStart, setShowCommentsStart] = useState(0);
  const [showCommentsEnd, setShowCommentsEnd] = useState(10);
  const toast = useToast();
  const [currentPostIndex, setCurrentPostIndex] = useState(0);

const currentPost = businessPosts[currentPostIndex];

  const fetchBusinessPosts = async () => {
    try {
      const response = await fetch('https://api.goodmanager.in/home/technology');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const formattedData = data.map(({ _id, category, date, preparation, title, subtitle, content, comments }) => ({
        id: _id,
        category,
        date,
        preparation,
        title,
        subtitle,
        content,
        comments,
      }));
      setBusinessPosts(formattedData);
    } catch (error) {
      console.error('Error fetching business posts:', error);
    }
  };

  useEffect(() => {
    document.title="Good Manager|Technology Management"

    fetchBusinessPosts();
  }, []);

  const handleCommentSubmit = async (postId) => {
    try {
      const response = await fetch(`https://api.goodmanager.in/home/technology/${postId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comment: commentText }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.message === 'Comment added successfully') {
        const updatedPosts = businessPosts.map((prevPost) => {
          if (prevPost.id === postId) {
            return {
              ...prevPost,
              comments: [data.new_comment, ...prevPost.comments],
            };
          }
          return prevPost;
        });

        setBusinessPosts(updatedPosts);

        toast({
          title: 'Comment Added',
          description: 'Your comment was added successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      setCommentText('');
      onClose();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const renderComments = (comments) => {
  
    const slicedComments = comments ? comments.slice().reverse() : [];
    const visibleComments = slicedComments.slice(showCommentsStart, showCommentsEnd); // Reverse the comments order
    const totalPages = Math.ceil(slicedComments.length / 5)-1;
  const currentPage =( Math.ceil(showCommentsEnd / 5)-1);
    return (
      <>
        {visibleComments.map((comment) => (
          comment && (
            <Box key={comment.comment_id} bg="gray.100" p="2" borderRadius="md" my="2" maxH="100px" overflowY="auto">
              <Text fontSize="sm" color="gray.600">
                <Text as="span" fontWeight="bold">{comment.user_id || 'Unknown User'}</Text>{' '}
                <Text as="span" color="gray.400">{comment.created_at}</Text>
              </Text>
              <Text mt="2">{comment.text}</Text>
            </Box>
          )
        ))}
        <Text fontSize="sm" color="gray.500" mt="4">
          {currentPage} page out of {totalPages} pages
        </Text>
      </>
    );
  };
  const handleNextComments = () => {
    const post = businessPosts[currentPostIndex];
    if (post && showCommentsEnd < post.comments.length) {
      const newEnd = Math.min(showCommentsEnd + 5, post.comments.length);
      const newStart = Math.min(showCommentsStart + 5, newEnd - 5);
      setShowCommentsStart(newStart);
      setShowCommentsEnd(newEnd);
    }
  };
  
  const handlePrevComments = () => {
    if (showCommentsStart >= 5) {
      setShowCommentsStart(showCommentsStart - 5);
      setShowCommentsEnd(showCommentsEnd - 5);
    }
  };
  return (
    <Box maxW="800px" mx="auto" py="6">
    <Heading as="h1" fontSize="2xl" mb="4">
       Learnings and Projects
    </Heading>
    {businessPosts.map((post) => (
      <Link key={post.id} to={`/home/technology/${post.id}`} style={{ textDecoration: 'none' }}>
        <Box bg="white" p="4" boxShadow="md" mb="4">
          <VStack align="start">
            <Badge colorScheme="blue">{post.category}</Badge>
            <HStack w="100%">
              <Text fontSize="sm" color="gray.500" flexGrow="1">
                {post.date}
              </Text>
              <Text fontSize="sm" color="gray.500" flexShrink="1" textAlign="right">
                Preparation: {post.preparation} hrs
              </Text>
            </HStack>
            <Heading as="h2" fontSize="xl" my="2">
              {post.title}
            </Heading>
            <Text fontSize="md" color="gray.600">
              {post.subtitle}
            </Text>
          </VStack>
        </Box>
      </Link>
    ))}
  </Box>
  );
};

export default Tech;
