import { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Divider,
  Button,
  VStack,
  HStack,
  IconButton,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Textarea,
  useToast,
  Spacer,
  Grid,
  Icon,
} from '@chakra-ui/react';
import { FcPrevious, FcNext } from 'react-icons/fc';
import { useParams } from 'react-router-dom';

const Detail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [commentText, setCommentText] = useState('');
  const [showCommentsStart, setShowCommentsStart] = useState(0);
  const [showCommentsEnd, setShowCommentsEnd] = useState(10);
  const [comments, setComments] = useState([]);
  const toast = useToast();

  const fetchPost = async () => {
    try {
      const response = await fetch(`https://api.goodmanager.in/home/technology/${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPost(data);
      setComments(data.comments || []);
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };
  useEffect(() => {
    
    fetchPost();
  }, [id]);

  
  const handleCommentSubmit = async () => {
    try {
      const response = await fetch(`https://api.goodmanager.in/home/technology/${id}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comment: commentText }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      if (data.message === 'Comment added successfully') {
        // Update comments
        setComments(prevComments => [data.new_comment, ...prevComments]);
  
        fetchPost();
  
  
        toast({
          title: 'Comment Added',
          description: 'Your comment was added successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      setCommentText('');
      onClose();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };
  
  
  const renderComments = () => {
    if (!post || !post.comments) return null;
  
    const reversedComments = post.comments.slice().reverse();
    const slicedComments = reversedComments.slice(showCommentsStart, showCommentsEnd);
    const totalPages = Math.ceil(reversedComments.length / 5) - 1;
    const currentPage = Math.ceil(showCommentsEnd / 5) - 1;
  
    return (
      <>
        {slicedComments.map((comment) => (
          comment && (
            <Box key={comment.comment_id} bg="gray.100" p="2" borderRadius="md" my="2" maxH="100px" overflowY="auto">
              <Text fontSize="sm" color="gray.600">
                <Text as="span" fontWeight="bold">{comment.user_id || 'Unknown User'}</Text>{' '}
                <Text as="span" color="gray.400">{comment.created_at}</Text>
              </Text>
              <Text mt="2">{comment.text}</Text>
            </Box>
          )
        ))}
        <Text fontSize="sm" color="gray.500" mt="4">
          {currentPage} page out of {totalPages} pages
        </Text>
      </>
    );
  };
  
  
  const handleNextComments = () => {
    if (post && showCommentsEnd < post.comments.length) {
      const newEnd = Math.min(showCommentsEnd + 5, post.comments.length);
      const newStart = Math.min(showCommentsStart + 5, newEnd - 5);
      setShowCommentsStart(newStart);
      setShowCommentsEnd(newEnd);
    }
  };

  const handlePrevComments = () => {
    if (showCommentsStart >= 5) {
      setShowCommentsStart(showCommentsStart - 5);
      setShowCommentsEnd(showCommentsEnd - 5);
    }
  };

  return (
    <Box maxW="800px" mx="auto" py="6">
      {post && (
        <Box bg="white" p="4" boxShadow="md" mb="4">
          <VStack align="start">
            <Badge colorScheme="blue">{post.category}</Badge>
            <Text fontSize="sm" color="gray.500">
              {post.date} - Preparation: {post.preparation} hours invested
            </Text>
            <Heading as="h2" fontSize="xl" my="2">
              {post.title}
            </Heading>
            <Text fontSize="md" color="gray.600">
              {post.subtitle}
            </Text>
            <Text mt="4">{post.content}</Text>
            <Divider my="4" />
            
            <VStack align="start" w="100%">
              <HStack w="100%">
                <Text fontWeight="bold">Comments</Text>
                <Text color="gray.500"> ({post.comments.length || 0})</Text>
                <Spacer />
                <Button colorScheme="blue" size="sm" onClick={() => {setCommentText(''); onOpen();}}>
                  Add Comment
                </Button>
              </HStack>
              <HStack w="100%" justify="space-between">
                <IconButton
                  icon={<Icon as={FcPrevious} />}
                  onClick={handlePrevComments}
                  disabled={showCommentsStart === 0}
                />
                <Grid templateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap={4} w="100%" h="700px">
                  {renderComments()}
                </Grid>
                <IconButton
                  icon={<Icon as={FcNext} />}
                  onClick={handleNextComments}
                  disabled={showCommentsEnd >= post.comments.length}
                />
              </HStack>
            </VStack>
            
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Comment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Textarea
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Enter your comment"
                    size="lg"
                    resize="vertical"
                    minH="100px"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={handleCommentSubmit}>
                    Submit
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default Detail;
