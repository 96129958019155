import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Divider } from '@chakra-ui/react';
import {Helmet} from 'react-helmet'

const Lander = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    document.title="My Managing Workbook"
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress === 100) {
          clearInterval(interval);
          navigate('/home');
          return prevProgress;
        }
        return prevProgress + 1;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div>
      <Box
        w="100vw"
        h="100vh"
        css={{
          backgroundImage: 'linear-gradient(to bottom, #0F2027, #203A43, #2C5364)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white', // Ensures text is visible on the gradient background
          textAlign: 'center', // Centers text horizontally
          fontFamily: 'cursive', // Ancient-resembling font style
          fontSize: '28px', // Adjust font size accordingly
          position: 'relative', // Positioning for progress bar
        }}
      >
        <Box>
          <Text fontWeight="bold" fontSize="3xl" color="white">
            "The Harmony Of Managing"
          </Text>
          <Divider w="80%" bg="white" my="8" />
          <Text fontSize="xl" color="whiteAlpha.800">
            - Vishnu Prasad
          </Text>
        </Box>
        {/* Progress Bar */}
        <Box
          position="absolute"
          bottom="0"
          left="0"
          width={`${progress}%`}
          height="5px"
          background="white"
          transition="width 0.1s ease-out"
        />
      </Box>
    </div>
  );
};

export default Lander;
