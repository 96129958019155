import { Flex, Text,Spacer } from '@chakra-ui/react';
import {Link} from 'react-router-dom';

const Footer = () => {
  return (
    <Flex
      as="footer"
      align="center"
      justify="left"
      padding="1rem"
      bg="gray.800"
      color="white"
    >
      <Text fontSize="sm" align="left">
        © 2024 GoodManager. All rights reserved.
      </Text>
      <Spacer />
      <Flex ml="2rem">
        <Link to="/home/about" mx="1rem">About Me</Link>
      
      </Flex>
      <Spacer />
      <Flex ml="2rem">
        <Link mx="1rem" to="/home/contact">Contact</Link>
      
      </Flex>
    </Flex>
  );
};

export default Footer;
